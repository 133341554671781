import React, { Dispatch, useContext, useEffect, useState } from "react";
import s from "./SearchLoop.module.scss";
import { ReactComponent as LoopIcon } from "../../assets/icons/loop.svg";
import SearchContext from "../../core/context/SearchContext";
import useDebounce from "../../helper/useDebounce";

interface ISearchLoop {
  active: boolean;
  setActive: Dispatch<React.SetStateAction<boolean>>;
}
const SearchLoop = ({ active, setActive }: ISearchLoop) => {
  const [isFullWidth, setSsFullWidth] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const { setSearchInput } = useContext(SearchContext);
  const debouncedSearch = useDebounce(inputValue, 600);

  //update search query with debounced (delayed) value to not fetch data on each key stroke
  useEffect(() => {
    setSearchInput(debouncedSearch);
  }, [debouncedSearch, setSearchInput]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setInputValue(e.target.value);
  };

  return (
    <div className={`${s.container} ${isFullWidth ? s.active : ""}`}>
      <LoopIcon
        onClick={() => {
          setSsFullWidth(!isFullWidth);
          setActive(!active);
        }}
      />

      <input
        type="text"
        className={isFullWidth ? s.active : ""}
        onChange={(e) => handleInput(e)}
        value={inputValue}
      />
    </div>
  );
};

export default SearchLoop;
