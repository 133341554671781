import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#548ad3", // Main app color
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            fontFamily: "Akrobat",
            fontWeight: 500,
            paddingInline: "5px 18px",
            "& fieldset": {
              borderColor: "#E6E6E6",
            },
          },
          "& label": {
            marginLeft: "8px",
            display: "flex",
            alignItems: "center",
            height: "25px",
            fontFamily: "Akrobat",
            fontWeight: 500,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingLeft: "16px",
        },
        root: {
          "& > fieldset": {
            border: "1px solid #E6E6E6",
          },
          "& > fieldset > legend": {
            fontSize: `0.72rem`,
            marginLeft: "8px",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
});
