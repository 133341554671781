import React, { useContext } from "react";
import s from "./GraphicSearchList.module.scss";

import DashboardTopHeader from "../../../../../components/DashboardTopHeader/DashboardTopHeader";
import { useTranslation } from "react-i18next";
import FilterComponent from "../../../../../components/FilterComponent/FilterComponent";
import GraphicComponent from "../../../../../components/GraphicComponent/GraphicComponent";
import SearchContext from "../../../../../core/context/SearchContext";

const GraphicListSearch = () => {
  const { t } = useTranslation();
  const { searchInput } = useContext(SearchContext);

  return (
    <>
      {searchInput.trim().length > 0 && (
        <div className={s.container}>
          <DashboardTopHeader header={t("Graphics")} navigateTo={"/media"} />
          <div className={s.list_container}>
            <FilterComponent
              endpoint="/media"
              defaultSort="-id"
              defaultPagination={30}
              defaultSearch={searchInput}
              isSearch={true}
              isPagination={true}
              render={({ data, onDelete }) => (
                <div className={s.map_container}>
                  {data?.map((el: any, i: number) => (
                    <GraphicComponent
                      key={`dashboard-graphic-last-map${i}`}
                      variant="medium"
                      id={`dashboard-graphic-last-map${i}`}
                      imgSrc={el?.url}
                      handleClick={() => {}}
                      onDelete={onDelete}
                      graphic={el}
                      componentOptions="1"
                    />
                  ))}
                </div>
              )}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GraphicListSearch;
