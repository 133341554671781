import React, { useContext, useState, useEffect } from "react";
import s from "./OnboardWorkspace.module.scss";
import { useTranslation } from "react-i18next";
import { InputAdornment, TextField } from "@mui/material";
import Button from "../../../components/Button/Button";
import { usePostRequest } from "../../../core/api/usePostRequest";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../core/context/AuthContext";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import Back from "../../../components/Back/Back";
import { isEmptyString } from "../../../helper/validation";
import { useApiRequest } from "../../../core/api/useApiRequest";
import Cookies from "js-cookie";
import CompleteLaterLink from "../../../components/CompleteLaterLink/CompleteLaterLink";

const OnboardWorkspace = () => {
  const [input, setInput] = useState({
    name: "",
    slug: "",
  });
  const [isNameValid, setIsNameValid] = useState(true);
  const [isSlugValid, setIsSlugValid] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);

  const { t } = useTranslation();
  const { sendRequest, isLoading } = useApiRequest();
  const { setUser, user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setIsFormValid(!isEmptyString(input.name) && !isEmptyString(input.slug));
  }, [input]);

  const inputHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "name") {
      setIsNameValid(!isEmptyString(value));
    } else if (name === "slug") {
      setIsSlugValid(!isEmptyString(value));
    }
  };

  const handleCreateProfile = async () => {
    if (!isFormValid) return;

    const response: any = await sendRequest("post", "account/workspace", input);
    if (!response) return;

    setUser((prevState: any) => ({
      ...prevState,
      workspace: response?.data,
    }));

    navigate("/design-studio");
    console.log(response, " response");
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <div className={s.headers}>
            <h2>VIDELINK</h2>
            <h3>Business</h3>
          </div>
          <h4>{t("OnboardWorkspaceComponent.Team")}</h4>

          <TextField
            name="name"
            onChange={inputHandler}
            className={s.input}
            id="outlined-basic"
            label={t("OnboardWorkspaceComponent.one")}
            variant="outlined"
            error={!isNameValid}
          />
          <span>{t("OnboardWorkspaceComponent.two")}</span>
          <TextField
            name="slug"
            onChange={inputHandler}
            className={s.input}
            label={t("Address url")}
            variant="outlined"
            error={!isSlugValid}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className={s.end_adorment}>
                    <span>.videlink.com</span>
                  </div>
                </InputAdornment>
              ),
            }}
          />
          <div className={s.buttons_container}>
            <Link to="/onboard/profile-setup">&#8592; {t("Go back")}</Link>
            <Button
              text={isLoading ? t("Loading") : t("Next")}
              type="button"
              handleClick={handleCreateProfile}
              disabled={!isFormValid}
              isLoading={isLoading}
            />
          </div>
          <div className="solid-line"></div>
          <CompleteLaterLink />
        </div>
      </div>
      <div className={s.middle}></div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default OnboardWorkspace;
