import React, { useState } from "react";
import s from "./RecoverPassword.module.scss";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import Button from "../../../components/Button/Button";
import { Link } from "react-router-dom";
import { validateEmail } from "../../../helper/validation";
import {
  uniqueID,
  useNotification,
} from "../../../core/context/notifications/NotificationProvider";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import { useApiRequest } from "../../../core/api/useApiRequest";
import MessageComponent from "../../../components/MessageComponent/MessageComponent";

const buttonStyles = { width: "160px" };

const RecoverPassword = () => {
  const [input, setInput] = useState<{ email: string }>({ email: "" });
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isLinkSent, setIsLinkSent] = useState<boolean>(false);

  const { t } = useTranslation();
  const notification = useNotification();
  const { sendRequest, error, isLoading } = useApiRequest();

  const inputHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });

    // Validate email on change
    if (name === "email") {
      setIsEmailValid(validateEmail(value));
    }
  };

  const handleResetForgottenPass = async () => {
    if (!isEmailValid) return;

    const responseData: any = await sendRequest(
      "post",
      "auth/password/email",
      input
    );
    if (responseData) {
      notification({
        type: "SUCCESS",
        id: uniqueID(),
        message: t("Auth.RecoveryLinkSent"),
      });
      //setInput({ email: "" });
      setIsEmailValid(false); // Reset email validation state
      setIsLinkSent(true);
    }
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <h2>VIDELINK</h2>{" "}
          {!isLinkSent ? (
            <>
              <span className={s.slogan}>
                {t("RecoverPasswordComponent.Forgot your password?")}
              </span>
              <p>{t("RecoverPasswordComponent.Provide email")}</p>
              <TextField
                name="email"
                onChange={inputHandler}
                value={input.email}
                className={s.input}
                id="outlined-basic"
                autoComplete="off"
                label={t("Address email")}
                variant="outlined"
                color="primary"
                error={!isEmailValid && input.email !== ""}
                onBlur={() => setIsEmailValid(validateEmail(input.email))}
              />
              <div className={s.error_container}>
                {error && (
                  <MessageComponent
                    text={t("RecoverPasswordComponent.errorOne")}
                    type="error"
                  />
                )}
              </div>
              <div className={s.half_container}>
                <Link to="/login">&#8592; {t("Back to login")}</Link>
                <Button
                  text={
                    isLoading
                      ? t("Loading")
                      : t("RecoverPasswordComponent.Recover password")
                  }
                  type="button"
                  handleClick={handleResetForgottenPass}
                  styles={buttonStyles}
                  isLoading={isLoading}
                  disabled={!isEmailValid || isLoading}
                />
              </div>
            </>
          ) : (
            <>
              <div className={s.text_container}>
                <span className={s.slogan}>
                  {t("RecoverPasswordComponent.LinkSent.one")}
                </span>
                <p>
                  {`${t("RecoverPasswordComponent.LinkSent.two")} `}
                  <span className={s.email}>{input.email} </span>
                  {`${t("RecoverPasswordComponent.LinkSent.three")}`}
                </p>
                <div />
                <span className={s.slogan}>
                  {t("RecoverPasswordComponent.LinkSent.four")}
                </span>
                <p>
                  {`${t("RecoverPasswordComponent.LinkSent.five")} `}
                  <span className={s.email}>no-reply@videlink.eu</span>.
                </p>
              </div>
              <Link to="/login">&#8592; {t("Back to login")}</Link>
            </>
          )}
        </div>
      </div>
      <div className={s.middle}></div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default RecoverPassword;
