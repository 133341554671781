import React, { useState } from "react";
import s from "./Payments.module.scss";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../../components/Button/Button";
import CompleteLaterLink from "../../../components/CompleteLaterLink/CompleteLaterLink";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AuthContext from "../../../core/context/AuthContext";
import { useContext } from "react";

type Method = "paypal" | "payu";
const buttonStyles = { width: "210px" };

interface IPayments {
  handleAfterPayment?: () => void;
}
const Payments = ({ handleAfterPayment }: IPayments) => {
  const [age, setAge] = useState<string>("");
  const [method, setMethod] = useState<Method>("paypal");
  const { t } = useTranslation();
  const { registrationStep, setRegistrationSteps } = useContext(AuthContext);
  const type =
    registrationStep.plan_isyYearly === "yearly" ? "Per year" : "Per month";

  const handlePayment = () => {
    // TODO: Handle payment
    if (method === "paypal") console.log("Payment with paypal");
    else if (method === "payu") console.log("Payment with payu");

    if (handleAfterPayment) handleAfterPayment();
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <h4>{t("PaymentComponent.Payment method")}</h4>
        <div className={s.payment_options}>
          <div
            className={`${method === "paypal" ? s.selected : ""}`}
            onClick={() => setMethod("paypal")}
          >
            <CreditCardIcon /> Paypal
          </div>
          <div
            className={`${method === "payu" ? s.selected : ""}`}
            onClick={() => setMethod("payu")}
          >
            <CreditCardIcon /> PayU
          </div>
        </div>
        <p>
          {t("PaymentComponent.Conditions one")}
          <Link to={"/"}>{t("PaymentComponent.Conditions two")}</Link>
        </p>
        <div className={s.buttons_container}>
          <Link to="/onboard/billing-info">&#8592; {t("Go back")}</Link>
          <Button
            text={t("Continue with") + " " + method}
            type="button"
            handleClick={handlePayment}
            styles={buttonStyles}
          />
        </div>
        {/* <TextField label="Karta kredytowa lub debetowa" />
        <div className={s.icon_container}>
          <CreditCardIcon />
          <CreditCardIcon />
          <CreditCardIcon />
        </div>
        <TextField label="Visa *7777" />
        <div className={s.input_group}>
          <TextField label="01" />
          <TextField label="2023" />
        </div>
        <TextField label="Videlink SPO" /> */}
        <div className="solid-line"></div>

        <CompleteLaterLink />
      </div>

      <div className={s.right}>
        <div className={s.plan_label}>{t("PaymentComponent.Your plan")}</div>
        <div className={s.header}>
          <h2>VIDELINK</h2>
          <h3>Business</h3>
        </div>
        <FormControl fullWidth>
          <label htmlFor="size-select">
            {t("PaymentComponent.Selected space")}
          </label>
          <Select
            className={s.select}
            id="size-select"
            size="small"
            defaultValue={10}
            IconComponent={KeyboardArrowDownIcon}
            // onChange={handleChange}
          >
            <MenuItem value={10}>10 GB</MenuItem>
            <MenuItem value={20}>20 GB</MenuItem>
            <MenuItem value={30}>50 GB</MenuItem>
          </Select>
        </FormControl>

        <div className="solid-line"></div>

        <div className={s.info}>
          <div>
            <span>{t("PaymentComponent.Subtotal")}</span>
            <span>
              249,00 PLN
              <span className={s.type}>/{t("PaymentComponent." + type)}</span>
            </span>
          </div>
          <div>
            <span>VAT 23%</span>
            <span>
              57.27 PLN
              <span className={s.type}>/{t("PaymentComponent." + type)}</span>
            </span>
          </div>
          <br />
          <div>
            <span className={s.highlight}>{t("PaymentComponent.Total")}</span>
            <span className={s.highlight}>
              306,27 PLN
              <span className={s.type}>
                /{t("PaymentComponent." + type)}
              </span>{" "}
              <br />
              <span>{t("With")} VAT</span>
            </span>
          </div>
        </div>
        <div className="solid-line"></div>
        <span className={s.plan_bottom}>
          {`${
            registrationStep.plan_isyYearly === "yearly"
              ? t("Yearly")
              : t("Monthly")
          } `}
          {t("PaymentComponent.Next payment one")} 21.11.2019{" "}
          {t("PaymentComponent.Next payment two")}
        </span>
      </div>
    </div>
  );
};

export default Payments;
