import { Link } from "react-router-dom";
import s from "./CompleteLaterLink.module.scss";
import { useTranslation } from "react-i18next";

export default function CompleteLaterLink() {
  const { t } = useTranslation();

  return (
    <span className={s.dashboard_link}>
      {t("CompleteLaterComponent.one")}{" "}
      <Link to="/dashboard">{t("CompleteLaterComponent.two")}</Link>
    </span>
  );
}
