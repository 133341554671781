import {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useContext,
} from "react";
import AuthContext from "./AuthContext";
import { useApiRequest } from "../api/useApiRequest";

interface ProductContextValue {
  products: any;
  setProducts: (products: any) => void;
  forceReloadFlag: boolean;
  setForceReloadFlag: (
    flag: boolean | ((prevFlag: boolean) => boolean)
  ) => void;
  isLoadingFetchProduct: boolean;
}

const ProductContext = createContext<ProductContextValue>({
  products: null,
  setProducts: () => {},
  forceReloadFlag: false,
  setForceReloadFlag: () => {},
  isLoadingFetchProduct: true,
});

export const ProductContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [products, setProducts] = useState<any>([]);
  const [forceReloadFlag, setForceReloadFlag] = useState<boolean>(false);
  const { sendRequest, isLoading: isLoadingFetchProduct } = useApiRequest();
  const { user } = useContext(AuthContext);

  // const fetchProducts = async () => {
  //   const response: any = await sendRequest("get", "/creator/2d/templates");
  //   setProducts(response?.data);
  // };

  // useEffect(() => {
  //   fetchProducts();
  // }, [forceReloadFlag, user]);

  return (
    <ProductContext.Provider
      value={{
        products,
        setProducts,
        forceReloadFlag,
        setForceReloadFlag,
        isLoadingFetchProduct,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContext;
