import s from "./AllSearchList.module.scss";
import { useContext } from "react";
import DashboardTopHeader from "../../../../../components/DashboardTopHeader/DashboardTopHeader";
import { useTranslation } from "react-i18next";
import SearchContext from "../../../../../core/context/SearchContext";
import FilterComponent from "../../../../../components/FilterComponent/FilterComponent";
import GraphicComponent from "../../../../../components/GraphicComponent/GraphicComponent";
import ProductComponent from "../../../../../components/ProductComponent/ProductComponent";
import FabricJSContextNew from "../../../../../core/context/FabricJSContextNew";
import { useNavigate } from "react-router-dom";
import ProjectComponent from "../../../../../components/ProjectComponent.tsx/ProjectComponent";

const AllSearchList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { searchInput } = useContext(SearchContext);
  const { setActiveGlobalState } = useContext(FabricJSContextNew);

  const productHandler = (selectedView: any, fullJSON: any) => {
    setActiveGlobalState({ canvasType: "templates", fullJSON: fullJSON });
    navigate(`/design-studio/product/${fullJSON.token}`);
  };

  const projectHandler = (selectedView: any, fullJSON: any) => {
    setActiveGlobalState({
      canvasType: "projects",
      fullJSON,
    });
    navigate(`/design-studio/project/${fullJSON?.token}`);
  };

  return (
    <>
      {searchInput.trim().length > 0 && (
        <div className={s.container}>
          <FilterComponent
            endpoint="/search"
            defaultSort="-id"
            defaultPagination={10}
            defaultSearch={searchInput}
            isSearch={true}
            isPagination={true}
            render={({ data, onDelete }) => (
              <>
                <DashboardTopHeader
                  header={t("Products")}
                  navigateTo={"/products"}
                />
                <div className={s.list_container}>
                  <div className={s.map_container}>
                    {data?.templates.map((el: any, i: number) => (
                      <ProductComponent
                        key={`dashboard-product-products-recent-map${i}`}
                        product={el}
                        variant="medium"
                        handleClick={(variant) => productHandler(variant, el)}
                        componentOptions="1"
                        id={`dashboard-product-products-recent-map${i}`}
                        onDelete={onDelete}
                      />
                    ))}
                  </div>
                </div>
                <DashboardTopHeader
                  header={t("Projects")}
                  navigateTo={"/projects"}
                />

                <div className={s.list_container}>
                  <div className={s.map_container}>
                    {data?.projects.map((el: any, i: number) => (
                      <ProjectComponent
                        key={`dashboard-projects-projects-recent-map${i}`}
                        project={el}
                        variant="medium"
                        handleClick={(variant) => projectHandler(variant, el)}
                        id={`dashboard-projects-projects-recent-map${i}`}
                        componentOptions="1"
                        onDelete={onDelete}
                      />
                    ))}
                  </div>
                </div>
                <DashboardTopHeader
                  header={t("Graphics")}
                  navigateTo={"/media"}
                />
                <div className={s.list_container}>
                  <div className={s.map_container}>
                    {data?.media.map((el: any, i: number) => (
                      <GraphicComponent
                        key={`dashboard-graphic-last-map${i}`}
                        variant="medium"
                        id={`dashboard-graphic-last-map${i}`}
                        imgSrc={el?.url}
                        handleClick={() => {}}
                        onDelete={onDelete}
                        graphic={el}
                        componentOptions="1"
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          />
        </div>
      )}
    </>
  );
};

export default AllSearchList;
