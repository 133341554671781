import React, { useContext, useState } from "react";
import s from "./Register.module.scss";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import Button from "../../../components/Button/Button";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../core/context/AuthContext";
import { validateEmail } from "../../../helper/validation";
import { useNotification } from "../../../core/context/notifications/NotificationProvider";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import { useApiRequest } from "../../../core/api/useApiRequest";
import { baseDomain } from "../../../core/utils";
import GoogleAuthButton from "../../../components/GoogleAuthButton/GoogleAuthButton";

const buttonStyles = { alignSelf: "flex-start" };

const OnboardProfile = () => {
  const [input, setInput] = useState<any>({
    email: "",
  });
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notification = useNotification();
  const { sendRequest, isLoading } = useApiRequest();
  const { setRegistrationSteps } = useContext(AuthContext);

  const inputHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setInput({
      ...input,
      [name]: value,
    });

    // Validate email immediately on change
    if (name === "email") {
      setIsEmailValid(validateEmail(value));
    }
  };

  const handleCreateProfile = async () => {
    if (!isEmailValid) return;

    const responseData: any = await sendRequest("post", "auth/signup", input);
    setRegistrationSteps((prev: any) => ({ ...prev, email: input.email }));
    if (responseData && responseData?.access_token) {
      localStorage.setItem("registrationEmail", input.email);
      //console.log(responseData?.access_token);

      Cookies.set("vl_access_token", responseData?.access_token, {
        domain: `.${baseDomain}`,
        path: "/",
      });

      navigate("/onboard/payment-plan");
    } else {
      console.log("Access token not present");
    }
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <h2>VIDELINK</h2>
          <div className={s.half_container_top}>
            <span className={s.slogan}>{t("RegisterComponent.one")}</span>

            <TextField
              name="email"
              onChange={(e) => inputHandler(e)}
              onBlur={() => setEmailTouched(true)}
              className={s.input}
              id="outlined-basic"
              autoComplete="off"
              label={t("Address email")}
              variant="outlined"
              color="primary"
              error={!isEmailValid && emailTouched}
            />
            <div className={s.button_container}>
              <Button
                text={isLoading ? t("Loading") : t("Next")}
                type="button"
                handleClick={handleCreateProfile}
                styles={buttonStyles}
                disabled={!isEmailValid || isLoading}
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className={s.half_container_bottom}>
            <p className={s.first}>{t("RegisterComponent.two")}</p>
            <Link to="/login">{t("RegisterComponent.three")}</Link>
            <p className={s.second}>{t("RegisterComponent.four")}</p>
            <GoogleAuthButton />
            <p className={s.third}>{t("RegisterComponent.five")}</p>
          </div>
        </div>
      </div>
      <div className={s.middle}></div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default OnboardProfile;
