import React from "react";
import s from "./Button.module.scss";
import LoadingState from "../LoadingState/LoadingState";

interface IButtonProps {
  handleClick?: () => void;
  onFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  text: string;
  type: "button" | "input";
  styles?: React.CSSProperties;
  disabled?: boolean;
  contained?: boolean;
  name?: string;
  isLoading?: boolean;
  isFlat?: boolean;
  onFileSelectedAndInvoke?: (file: File | null) => void; // New prop
  icon?: any;
  isThreePiece?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  handleClick,
  onFileChange,
  text,
  type,
  styles = {},
  disabled = false,
  contained = false,
  name,
  isLoading = false,
  isFlat = false,
  onFileSelectedAndInvoke, // New prop
  icon,
  isThreePiece,
}) => {
  const handleClickInternal = () => {
    if (!disabled && handleClick) {
      handleClick();
    }
  };

  const handleFileChangeInternal = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0] || null;

    // Call the provided file change handler if exists
    if (onFileChange) {
      onFileChange(event);
    }

    // Invoke the passed function if provided with the selected file
    if (onFileSelectedAndInvoke) {
      onFileSelectedAndInvoke(file);
    }
    event.target.value = ""; // Reset the input value
  };

  const computedButtonStyles = {
    ...styles,
    ...(disabled && {
      cursor: "not-allowed",
      opacity: 0.9,
      cursorEvennts: "none",
    }),
  };

  const buttonStyleClasses =
    s.button +
    " " +
    `${
      contained
        ? s.contained
        : s.outlined + (!disabled ? " " + s.enabled_outlined_button : "")
    }`;

  const renderButtonContent = () => (
    <div
      className={buttonStyleClasses}
      style={{
        ...computedButtonStyles,
        ...(isFlat && { padding: "10px 50px" }),
      }}
    >
      {text}
    </div>
  );

  return type === "input" ? (
    <label
      className={`${s.container} ${s.input} ${s.container_outline} ${
        name ? s.selectedName : ""
      } ${
        !disabled ? s.enabled_container + " " + s.enabled_container_outline : ""
      }`}
      style={computedButtonStyles}
    >
      {isLoading ? (
        <LoadingState text={text} />
      ) : (
        <>
          <input
            type="file"
            onChange={handleFileChangeInternal} // Updated to use internal handler
            style={{ display: "none" }}
            disabled={disabled}
          />
          {renderButtonContent()}
        </>
      )}
    </label>
  ) : (
    <div
      className={
        s.container +
        ` ${
          contained
            ? s.shadow
            : s.container_outline +
              (!disabled ? " " + s.enabled_container_outline : " ")
        } ${!disabled ? s.enabled_container : ""}`
      }
      style={computedButtonStyles}
      onClick={handleClickInternal}
    >
      {isLoading ? <LoadingState text={text} /> : renderButtonContent()}
    </div>
  );
};

export default Button;
