import React, { useContext, useEffect, useState } from "react";
import s from "./OnboardBillingInfo.module.scss";
import { useTranslation } from "react-i18next";
import { usePostRequest } from "../../../core/api/usePostRequest";
import Button from "../../../components/Button/Button";
import AuthContext from "../../../core/context/AuthContext";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import {
  uniqueID,
  useNotification,
} from "../../../core/context/notifications/NotificationProvider";
import { Link, useNavigate } from "react-router-dom";
import AddressForm, {
  AddressInfo,
} from "../../../components/AddressForm/AddressForm";

const buttonStyle = { marginLeft: "auto", width: "160px" };

const defaultValue = {
  billing_name: "",
  billing_street: "",
  billing_city: "",
  billing_postcode: "",
  billing_tin: "",
};

const OnboardBillingInfo = () => {
  const { t } = useTranslation();
  const { postData } = usePostRequest();
  const notification = useNotification();
  const [input, setInput] = useState<AddressInfo>(defaultValue);
  const [isValid, setIsValid] = useState<boolean>(false);
  const navigate = useNavigate();
  //const { user, setForceFetch } = useContext(AuthContext);

  //TODO: handle post data
  const handlePostData = async () => {
    if (!isValid) return;

    const response = await postData("/account/billing", input);
    if (response) {
      navigate("/onboard/payment");
      notification({
        type: "SUCCESS",
        message: t("Request successful"),
        id: uniqueID(),
      });
    } else {
      console.log(response);
    }
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <div className={s.header}>
            <h2>VIDELINK</h2>
            <h3>Business</h3>
          </div>
          <span>{t("Billing Information")}</span>
          <AddressForm
            setIsValid={setIsValid}
            setInput={setInput}
            input={input}
          />
          <div className={s.buttons_container}>
            <Link to="/onboard/payment-plan">&#8592; {t("Go back")}</Link>
            <Button
              text={t("Next")}
              type="button"
              handleClick={handlePostData}
              styles={buttonStyle}
              disabled={!isValid}
            />
          </div>
        </div>
      </div>
      <div className={s.middle}></div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default OnboardBillingInfo;
