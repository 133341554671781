import React, { useContext, useEffect, useState } from "react";
import s from "./ChangePassword.module.scss";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Button from "../../../components/Button/Button";
import { usePostRequest } from "../../../core/api/usePostRequest";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AuthContext from "../../../core/context/AuthContext";
import {
  isEmptyString,
  validateEmail,
  validatePassword,
} from "../../../helper/validation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Cookies from "js-cookie";
import {
  uniqueID,
  useNotification,
} from "../../../core/context/notifications/NotificationProvider";
import AuthSwiper from "../AuthSwiper/AuthSwiper";
import { useApiRequest } from "../../../core/api/useApiRequest";
import MessageComponent, {
  MessageProps,
} from "../../../components/MessageComponent/MessageComponent";

const buttonStyles = { width: "160px" };
const ChangePassword = () => {
  const [input, setInput] = useState<any>({
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordConfirmationValid, setIsPasswordConfirmationValid] =
    useState(true);

  const { t } = useTranslation();
  const [message, setMessage] = useState<MessageProps>({
    type: "info",
    text: t("ProfileSetupComponent.Message"),
  });
  const navigate = useNavigate();
  const notification = useNotification();
  const { sendRequest, isLoading } = useApiRequest();
  const { token } = useParams();
  const email = searchParams.get("email");
  const { setReloadFlagAuth } = useContext(AuthContext);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Validate the entire form on input changes
    setIsFormValid(
      !isEmptyString(input?.password) &&
        validatePassword(input?.password) &&
        input?.password === input?.password_confirmation
    );
  }, [input]);

  const inputHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setInput({
      ...input,
      [name]: value,
      email: email,
      token,
    });

    // Validate individual inputs on change
    let messageType: "info" | "error" = "info";
    let messageText = t("ProfileSetupComponent.Message");

    if (name === "password") {
      const isValid = !isEmptyString(value) && validatePassword(value);
      setIsPasswordValid(isValid);
      if (!isValid) {
        messageType = "error";
        messageText = t("ProfileSetupComponent.Message");
      } else {
        const isConfirmationValid = value === input?.password_confirmation;
        setIsPasswordConfirmationValid(value === input?.password_confirmation);
        if (!isConfirmationValid) {
          messageType = "error";
          messageText = t("ProfileSetupComponent.Error confirmation");
        }
      }
    } else if (name === "password_confirmation") {
      const isValid = value === input?.password;
      if (!validatePassword(input.password)) {
        messageType = "error";
        messageText = t("ProfileSetupComponent.Message");
      } else if (!isValid) {
        messageType = "error";
        messageText = t("ProfileSetupComponent.Error confirmation");
      }
      setIsPasswordConfirmationValid(isValid);
    }
    setMessage({ type: messageType, text: messageText });
  };

  console.log(token);
  const handleChangePassword = async () => {
    if (!isFormValid) return;

    const responseData: any = await sendRequest(
      "post",
      "auth/password/reset",
      input
    );
    console.log(responseData);
    if (responseData) {
      notification({
        type: "SUCCESS",
        id: uniqueID,
        message: t("Auth.PasswordRecovered"),
      });
      Cookies.set("vl_access_token", responseData.access_token);
      setReloadFlagAuth((prev: boolean) => !prev);
      navigate("/dashboard");
    }
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.wrapper}>
          <h2>VIDELINK</h2>

          <span className={s.slogan}>{t("Change password")}</span>

          <TextField
            name="email"
            onChange={(e) => inputHandler(e)}
            className={s.input}
            id="outlined-basic"
            autoComplete="off"
            label={t("Address email")}
            variant="outlined"
            color="primary"
            value={email}
            error={!isEmailValid}
            disabled={true}
            onBlur={() => setIsEmailValid(validateEmail(input.email))}
          />

          <TextField
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={(e) => inputHandler(e)}
            className={s.input}
            label={t("Password")}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="password_confirmation"
            type={showConfirmPassword ? "text" : "password"}
            onChange={(e) => inputHandler(e)}
            className={s.input}
            label={t("Repeat password")}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className={s.message_container}>
            {(!isPasswordValid || !isPasswordConfirmationValid) && (
              <MessageComponent {...message} />
            )}
          </div>
          <div className={s.buttons_container}>
            <Link to="/login">&#8592; {t("Back to login")}</Link>
            <Button
              text={isLoading ? t("Loading") : t("Save new password")}
              type="button"
              handleClick={() => handleChangePassword()}
              styles={buttonStyles}
              isLoading={isLoading}
              disabled={!isFormValid}
            />
          </div>
        </div>
      </div>
      <div className={s.middle}></div>
      <div className={s.right}>
        <AuthSwiper />
      </div>
    </div>
  );
};

export default ChangePassword;
